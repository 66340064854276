<template>
  <div style="width: inherit; height: inherit">
    <v-select
      v-model="column"
      :items="items"
      item-text="name"
      item-value="column"
      label="Select Atribute"
      @change="reload"
    />
    <div
      ref="echart"
      v-resize="onResize"
      style="width: inherit; height: inherit"
    />
  </div>
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import ApiService from '../../../services/api.service';
import { createSeriesOption } from './lib/transformer';

import { createCategoryQueryParams } from '../../../lib/query-creator';

export default {
  components: {},
  props: ['dataset'],
  data() {
    return {
      chart: null,
      column: '"Option_1"',
      items: [
        {
          name: 'Location',
          code: 'location',
          column: '"Location"',
          display: 'multi-select',
        },
        {
          name: 'Option 1',
          code: 'option_1',
          column: '"Option_1"',
          display: 'multi-select',
        },
        {
          name: 'Option 2',
          code: 'option_2',
          column: '"Option_2"',
          display: 'multi-select',
        },
        {
          name: 'Option 3',
          code: 'option_3',
          column: '"Option_3"',
          display: 'multi-select',
        },
        {
          name: 'Option 4',
          code: 'option_4',
          column: '"Option_4"',
          display: 'multi-select',
        },
        {
          name: 'Option 5',
          code: 'option_5',
          column: '"Option_5"',
          display: 'multi-select',
        },
        {
          name: 'Data Type',
          code: 'data_type',
          column: '"Data_Type"',
          display: 'multi-select',
        },
        {
          name: 'Criticality',
          code: 'criticality',
          column: '"Criticality"',
          display: 'multi-select',
        },
        {
          name: 'Natural Frequency',
          code: 'natural_frequency',
          column: '"Natural_Frequency"',
          display: 'multi-select',
        },
        {
          name: 'Location Type',
          code: 'location_type',
          column: '"Location_Type"',
          display: 'multi-select',
        },
      ],
    };
  },
  computed: {},
  async mounted() {
    this.init();
    this.reload();
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.echart);
    },
    draw(option) {
      // this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {},
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    async reload() {
      try {
        this.$store.commit('mSetLoading', true);
        await this.loadData();
      } catch (error) {}
      this.$store.commit('mSetLoading', false);
    },
    async loadData() {
      if (this.dataset && this.dataset.table_name) {
        const appConfig = this.$store.state.appConfig;

        // console.log('this.dataset', this.dataset);
        const column = this.column.replaceAll('"', '');
        const result = createCategoryQueryParams(this.dataset, this.column);
        // const sql = createSeriesQuery(this.dataset);
        const response = await ApiService.post('/raw_sql/', { q: result.sql });
        const params = {
          valueColumn: 'value',
          labelColumn: column,
        };
        const data = response.data;
        const option = createSeriesOption(data, params);
        // console.log('option', option);
        this.draw(option);
      }
    },
  },
};
</script>
